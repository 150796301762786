import React, {useCallback, useContext, useEffect, useState} from "react";
import { useIntl, FormattedMessage } from "react-intl";
import Input from "../../Input";
import Modal from "../../Modal";
import Toggle from "../../Toggle";
import Button from "../../Button";
import { NotificationsContext } from "../../Notifications";
import Placeholder from "../../Placeholder";
import RadioGroup from "../../RadioGroup";
import { api } from "@services/apiRequest";
import useRoles from "./hooks/useRoles";
import {useLogin} from "feature/login/hooks/useLogin";
import _ from "lodash";
import Select from "@ui-components/Select";

function AddUserDialog({ open, toggleOpen, users, setUsers }) {

  // context
  const { push } = useContext(NotificationsContext);

  // hooks
  const intl = useIntl();
  const roles = useRoles();
  const {methods} = useLogin();

  // state
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [roleId, setRoleId] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [method, setMethod] = useState("");

  // utilities
  const methodsOptions = !!methods
    // If methods are available, this const will contain an object structured like `{method: "Method Name"}
    ? Object.entries(methods)
      .reduce(
        (acc, [key, value]) => {
          // If the current entry is set to false, it means that the method is not available - skip it
          if (value === false)
            return acc;
          else if (key === "client_sso_name") {
            // If the client_sso is available, we need to add the client name to the method
            if (!methods.client_sso)
              return acc;
            const v = intl.formatMessage(
              {id: "auth_method_client_sso", defaultMessage: "{client} SSO"},
              {client: _.capitalize(value)}
            );
            return {...acc, client_sso: v};
          }
          else if (key !== "client_sso") {
            // For every other method different from client_sso, we just add it
            const v = intl.formatMessage({id: `auth_method_${key}`});
            return {...acc, [key]: v};
          }
          return acc;
        },
        {"": ""}  // Unselectable option, default value
      )
    : {};

  useEffect(() => {
    // When methods are loaded, we set the first available method as the default one
    if (!methods) return;
    setMethod(Object.keys(methodsOptions)[0]);
  }, [methods]);  // eslint-disable-line react-hooks/exhaustive-deps

  const _onSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();

        if (!roleId) {
          push({
            title: intl.formatMessage({ id: "need_to_select_a_role" }),
            type: "error",
          });
          return;
        }

        if (!email || !name) {
          push({
            title: intl.formatMessage({ id: "enter_name_or_email" }),
            type: "error",
          });
          return;
        }

        if (!method){
          push({
            title: intl.formatMessage({
              id: "enter_authentication_method",
              defaultMessage: "Seleziona il metodo di autenticazione dell'utente",
            }),
            type: "error",
          });
          return;
        }

        setLoading(true);
        const { data } = await api.post("/users", {
          email,
          full_name: name,
          role_id: roleId,
          disabled,
          needs_password_setup: method === "standard_login",
        });
        setUsers({ ...users, [data.id]: data });
        toggleOpen();
        push({
          title: intl.formatMessage({ id: "user_created_successfully" }),
          type: "success",
        });
      } catch (error) {
        if(error?.response?.data?.detail === "email_already_in_use"){
          push({
            title: intl.formatMessage({
              id: "email_already_in_use",
            }),
            type: "error",
          });                
        } else {
          push({
            title: intl.formatMessage({
              id: "server_error",
            }),
            type: "error",
          });
        }
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    [email, name, roleId, method, disabled, setUsers, toggleOpen, users, push]
  );
  return (
    <div>
      <Modal opened={open} onExit={toggleOpen}>
        <div className="m-8">
          <h3 className="text-center text-2xl font-extrabold text-gray-900">
            <FormattedMessage id="add_user" />
          </h3>
        </div>

        <form id="add-user-form" onSubmit={_onSubmit}>
          <div>
            <Input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              label={intl.formatMessage({
                id: "name_and_surname",
              })}
              type="text"
              errorMessage={intl.formatMessage({
                id: "enter_name_and_surname",
              })}
              required
              data-cy="user-name"
            />
          </div>
          <div>
            <Input
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              label="Email"
              type="email"
              errorMessage={intl.formatMessage({
                id: "enter_email",
              })}
              required
              data-cy="user-email"
            />
          </div>
          <div className="mb-4">
            {roles ? (
              <div>
                <RadioGroup
                  horizontal
                  title={intl.formatMessage({
                    id: "role",
                  })}
                  options={roles.map((role) => ({
                    value: role.id,
                    name:
                      role.name.charAt(0).toUpperCase() + role.name.slice(1),
                  }))}
                  currentValue={roleId}
                  onChange={(value) => {
                    setRoleId(value);
                  }}
                />
              </div>
            ) : (
              <div>
                <Placeholder width="w-4/5"/>
                <Placeholder width="w-3/5"/>
              </div>
            )}
          </div>

          <div className="mb-6">
            <Select
              value={method}
              onChange={(e) => setMethod(e.target.value)}
              options={
                Object.entries(methodsOptions).map(([value, name]) => ({value, name}))
              }
              label={intl.formatMessage({
                id: "authentication_method",
                defaultMessage: "Metodo di autenticazione",
              })}
              required
              disabled={!methods}
              data-cy="auth-method"
            />
            <Toggle
              vertical
              label={intl.formatMessage({
                id: "disabled_user",
              })}
              checked={disabled}
              onChange={() => setDisabled(!disabled)}
            />
          </div>
          <div className="mt-4 flex items-end justify-end">
            <Button
              submitting={loading}
              type="submit"
              data-cy="add-user-button"
            >
              <FormattedMessage id="add"/>
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default AddUserDialog;
