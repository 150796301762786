import React from "react";
import Label from "./Label";
import Checkbox from "./Checkbox";

const CheckboxGroup = ({
  options,
  values = [],
  title,
  description,
  onChange,
  horizontal,
  labelClasses = "",
}) => {
  if (!options || !options.length) {
    throw new Error("missing options");
  }

  return (
    <div className="mb-1">
      {title ? (
        <Label
          className={`text-base font-medium text-gray-900 ${labelClasses}`}
        >
          {title}
        </Label>
      ) : null}
      {description ? (
        <p className="text-sm leading-5 text-gray-500">{description}</p>
      ) : null}
      <fieldset
        className={
          horizontal
            ? "mt-1 flex flex-wrap -mx-4"
            : "mt-1 flex flex-col space-y-4"
        }
      >
        {options.map(({ value, name }) => {
          return (
            <div
              key={value}
              onClick={(e) => {
                onChange(value);
              }}
              className={
                horizontal
                  ? "relative flex items-start mb-2 ml-4"
                  : "relative flex items-start"
              }
            >
              <div className="flex items-center h-5">
                <Checkbox
                  id={value}
                  name={value}
                  checked={values.indexOf(value) >= 0}
                />
              </div>
              <div className="ml-2 text-sm">
                <label className="font-medium text-gray-700">{name}</label>
              </div>
            </div>
          );
        })}
      </fieldset>
    </div>
  );
};

export default CheckboxGroup;
