import React, {useState} from "react";
import { useIntl } from "react-intl";
// UI
import MaterialTable from "@material-table/core";
import _ from "lodash";

// Table Params
import { getColumns, OPTIONS, getLocalization } from "./Params";
import { tableIcons } from "../../TableIcons";
import AuthenticationService from "../../../services/AuthenticationService";
import { api } from "@services/apiRequest";
import useRoles from "./hooks/useRoles";
import TableStyle from "../../TableStyle";

function UsersTable({ users, setUsers, push, activeUserRole }) {

  const intl = useIntl();
  const [columns] = useState(getColumns(intl, activeUserRole))
  const [localization] = useState(getLocalization(intl))

  const scopes = AuthenticationService.getUserScopes();
  const roles = useRoles();

  function manageUpdatedUser(oldData, newData) {
    const { [oldData.id]: _, ...rest } = users;
    const updatedUser =
      newData.role_id !== newData.role.id
        ? {
            ...newData,
            role: roles.find((r) => r.id === parseInt(newData.role_id)),
          }
        : newData;

    setUsers({ ...rest, [newData.id]: updatedUser });
  }

  const _onRowUpdate = (newData, oldData) =>
    new Promise((resolve, reject) => {
      if(newData.email){
        newData.email = newData.email.trim().toLowerCase();
      }
      
      let isEqual = true;
      Object.entries(newData).forEach(([key, value]) => {
        if (oldData[key] !== value) {
          isEqual = false;
        }
      });
      if (isEqual) {
        resolve();
      } else {
        api
          .post(`/users/${oldData.id}`, newData)
          .then(() => {
            manageUpdatedUser(oldData, newData);
            push({
              title: intl.formatMessage({
                id: "user_updated_successfully",
              }),
              type: "success",
            });
            resolve();
          })
          .catch((error) => {
            console.error("Error during user update!", error);
            if(error?.response?.data?.detail === "email_already_in_use"){
              push({
                title: intl.formatMessage({
                  id: "email_already_in_use",
                }),
                type: "error",
              });                
            } else {
              push({
                title: intl.formatMessage({
                  id: "server_error",
                }),
                type: "error",
              });
            }
            reject(new Error("Server error"));
          });
        }
    });

  const _onRowDelete = (newData) =>
    new Promise((resolve, reject) => {
      api
        .delete(`/users/${encodeURIComponent(newData.id)}`)
        .then(() => {
          const { [newData.id]: _, ...rest } = users;
          setUsers({ ...rest });
          push({
            title: intl.formatMessage({
              id: "user_deleted_successfully",
            }),
            type: "success",
          });
          resolve();
        })
        .catch((err) => {
          console.error("Error during user delete!", err);
          push({
            title: intl.formatMessage({
              id: "server_error",
            }),
            type: "error",
          });
          reject(new Error());
        });
    });

  return (
    <TableStyle>
      <MaterialTable
        columns={columns}
        data={users ? Object.values(users) : []}
        icons={tableIcons}
        isLoading={users === undefined}
        options={OPTIONS}
        localization={localization}
        editable={{
          ...(scopes.indexOf("user:update") >= 0
            ? { 
                onRowUpdate: _onRowUpdate,
                isEditable: rowData => activeUserRole.val_hierarchy !== null && activeUserRole.val_hierarchy <= rowData.role.val_hierarchy,
                isEditHidden: rowData => activeUserRole.val_hierarchy !== null && activeUserRole.val_hierarchy > rowData.role.val_hierarchy
              }
            : {}),
          ...(scopes.indexOf("user:delete") >= 0
            ? { 
                onRowDelete: _onRowDelete,
                isDeletable: rowData => activeUserRole.val_hierarchy !== null && activeUserRole.val_hierarchy <= rowData.role.val_hierarchy,
                isDeleteHidden: rowData => activeUserRole.val_hierarchy !== null && activeUserRole.val_hierarchy > rowData.role.val_hierarchy
              }
            : {}),
        }}
      />
    </TableStyle>
  );
}

export default React.memo(UsersTable, (props, nextProps) => {
  // Render component only when users change
  return _.isEqual(props.users, nextProps.users);
});
