import React from "react";
import SelectRole from "./SelectRole";
import { isEmailValid } from "@utils/validations";

export const getColumns = (intl, activeUserRole) => [
  {
    title: "E-mail",
    field: "email",
    defaultSort: "asc",
    validate: rowData => {      
      return isEmailValid(rowData.email) || intl.formatMessage({ id: "enter_email" });
    },
  },
  {
    title: intl.formatMessage({ id: "name" }),
    field: "full_name",
    render: ({ full_name }) => <b>{full_name}</b>,
  },
  {
    title: intl.formatMessage({ id: "role" }),
    field: "role_id",
    customFilterAndSearch: (rolename, rowData) => rowData.role.name.startsWith(rolename),
    render: ({ role }) => <b className="capitalize">{role.name}</b>,
    editComponent: (props) => 
      activeUserRole !== null && activeUserRole.val_hierarchy < 0 ? <SelectRole {...props} /> : <b className="capitalize">{props.rowData.role.name}</b>,
    cellStyle: {
      minWidth: 200,
    },
  },

  {
    title: intl.formatMessage({ id: "disabled" }),
    field: "disabled",
    type: "boolean",
  },
];

export const OPTIONS = {
  filtering: true,
  pageSize: 10,
  searchFieldAlignment: "left",
  searchFieldStyle: {
    marginTop: "2px",
  },
  showTitle: false,
  toolbar: false,
};

export const getLocalization = (intl) => ({
  header: {
    actions: "",
  },
  body: {
    editRow: {
      deleteText: intl.formatMessage({ id: "delete_user" }),
    },
  },
  pagination: {
    labelDisplayedRows: intl.formatMessage({ id: "users_displayed_rows" }),
    labelRowsSelect: intl.formatMessage({ id: "users"}),
  },
});