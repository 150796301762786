import it_faq from "./faq/it_faq";
import it_form from "./form/it";

const it = {
  no_options_available: "Nessuna opzione disponibile",
  entity_user: "Gestione delle utenze in sistema",
  entity_role: "Gestione dei ruoli di autorizzazione in sistema",
  name_and_surname: "Nome e cognome",
  entity_audit: "Gestione dell'audit",
  language_it: "Italiano",
  language_en: "English",
  language: "Lingua",
  Home: "Home",
  disabled_user: "Utente disabilitato",
  enter_name_and_surname: "Inserisci nome e cognome",
  enter_name_or_email: "Inserisci email e nome",
  need_to_select_a_role: "Devi selezionare un ruolo",
  delete_user: "Eliminare l'utenza?",
  disabled: "Disabilitato",
  user_updated_successfully: "Utenza aggiornata con successo",
  user_deleted_successfully: "Utenza eliminata con successo",
  user_created_successfully: "Utente creato con successo",
  description: "Descrizione",
  role_name: "Nome ruolo",
  add_role_name: "Inserisci il nome del ruolo",
  add_role: "Aggiungi ruolo",
  add_user: "Aggiungi utente",
  deleted_role: "Ruolo eliminato",
  updated: "Aggiornati",
  delete_element: "Eliminare l'elemento?",
  role_already_used: "Ruolo già in uso!",
  role_created_successfully: "Ruolo creato con successo",
  add: "Aggiungi",
  server_error: "Errore del server",
  confirm_new_password: "Conferma nuova password",
  passwords_not_matching: "Le due password non coincidono",
  password_without_spaces: "La password non deve contenere spazi",
  password_length: "La password deve essere lunga almeno 8 caratteri",
  signup: "Registrati",
  error_while_saving: "Errore durante il salvataggio",
  authentication_failed: "Autenticazione fallita",
  send: "Invia",
  user_not_found: "Utente non trovato",
  wrong_old_password: "Vecchia password errata",
  enter_actual_password: "Inserisci password attuale",
  actual_password: "Password attuale",
  new_password: "Nuova password",
  email: "Email",
  name: "Nome",
  role: "Ruolo",
  user_type: "Tipo utente",
  my_profile: "Il mio profilo",
  mfa: "Multi Factor Authentication",
  access_denied: "Accesso negato",
  password_updated_successfully: "Password modificata con successo",
  submit_password: "Invia password",
  enter_your_email_address:
    "Inserisci l'indirizzo email associato alla tua utenza",
  restore_password: "Recupera password",
  sign_in: "Accedi",
  client_sso: "client sso",
  accenture_sso: "accenture sso",
  forgot_password: "Recupera password",
  edit_password: "Modifica password",
  show_password: "Mostra password",
  form_error: "Controlla i campi del form",
  enter_email: "Inserisci email",
  missing_sender_mail: "Mail del mittente mancante",
  log_into_your_account: "Entra nel tuo account",
  wrong_email_or_password: "Email o password sbagliati. Tentativi rimasti: {number}",
  wrong_otp: "Errore otp",
  user_locked: "Account utente bloccato per troppi tentativi, aspetta 30 minuti e riprova",
  password_expired: "Password scaduta, resettare la password utilizzando il tasto \"Recupera password\"",
  password_expiring: "La tua password scadrà tra {days} giorni",
  password_expiring_title: "Password in scadenza!",
  unregistered_email: "Email non registrata",
  forgot_password_msg: "Mail inviata! Se non ricevi alcuna notizia da noi nei prossimi 15 minuti, controlla di aver inserito l'indirizzo e-mail corretto e controlla nella cartella spam.",
  roles: "Ruoli",
  roles_displayed_rows: "{from}-{to} di {count} ruoli",
  users: "Utenti",
  users_displayed_rows: "{from}-{to} di {count} utenti",
  components: "Componenti",
  maps: "Mappe",
  tables: "Tabelle",
  graphs: "Grafici",
  users_settings: "Gestione utenze",
  welcome: "Benvenuto",
  test: "test_it",
  audit: "Audit",
  frontend: "Frontend",
  backend: "Backend",
  file_picker_placeholder: "Seleziona uno o più file",
  success_ticket_sent: "Il ticket è stato aggiunto correttamente, prenderemo in carico la richiesta il prima possibile.",
  hubspot_portal: "Hubspot Customer Portal",
  size_limit_exceeded: "Dimensione massima superata",
  customer_portal_loading_failed: "Errore durante il caricamento del link al portale cliente",
  week_datepicker: "Settimana ",
  week_datepicker_not_selected: "Datepicker settimanale",
  daily_datepicker_not_selected: "Datepicker giornaliero",
  thanks: "Grazie!",
  select_an_option: "Seleziona un'opzione dal menu a tendina.",
  ticket_parameters_not_found: "Parametri di invio ticket non presenti",
  troisi_parameters_not_found: "Parametri di invio mail non presenti",
  error_new_ticket_creation: "Errore imprevisto nella creazione di un nuovo ticket",
  error_ticket_hubspot_management: "É stato creato un nuovo ticket, ma qualcosa è andato storto durante le operazioni effettuate dal nostro portale",
  error_ticket_file_attachment: "É stato creato un nuovo ticket, ma non è possibile allegare il file specificato",
  session_expired: "Sessione scaduta",
  refreshing_session_wait: "Rinnovo della sessione in corso, attendere...",
  email_already_in_use: "Email già in uso",
  mfa_guide_guide1_title: "Perché è necessario?",
  mfa_guide_guide1_text: "L'autenticazione a due fattori (2FA) aggiunge un ulteriore livello di sicurezza al tuo account. Anche se qualcuno riesce a scoprire la tua password, non potrà accedere al tuo account senza il codice di verifica impostato sul tuo dispositivo.",
  mfa_guide_guide2_title: "Cosa mi serve per l'autenticazione?",
  mfa_guide_guide2_text: "È necessario scaricare un’app apposita per l’autenticazione multi-fattori sul tuo smartphone. Ecco come:",
  mfa_guide_guide2_text_item1: "Scarica dall’App store o Play store un’app di autenticazione, come Google Authenticator o Microsoft Authenticator",
  mfa_guide_guide2_text_item2: "Apri l’app e aggiungi un account. Nel caso di Microsoft Authenticator, è necessario prima selezionare “account aziendale o dell’istituto di istruzione”",
  mfa_guide_guide2_text_item3: "Per collegare la tua app al tuo account sulla piattaforma è ora sufficiente inquadrare il codice QR dall’applicazione, o inserire il codice numerico",
  mfa_guide_guide2_text_item4: "Se l’applicazione mostra il codice di accesso a 6 cifre, hai terminato l’installazione!",
  mfa_guide_guide3_title: "Non riesco ad accedere",
  mfa_guide_guide3_text: "Se il codice di verifica non funziona, contatta l'amministratore del sistema al seguente indirizzo: {address}",
  authentication_method: "Metodo di autenticazione",
  auth_method_accenture_eso: "Accenture SSO",
  auth_method_client_sso: "{client} SSO",
  auth_method_standard_login: "Email e password",
  generic_error: "Errore imprevisto",
  ...it_faq,
  ...it_form,
};

export default it;
